/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Zagreb"), React.createElement("p", null, "Pour se loger, Zagreb s’articule en trois quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hr/zagreb/gornjigrad.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gornji Grad"), " : la ville haute, avec le parlement, la cathédrale et les terrasses bondées de la rue Tkalčićeva."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hr/zagreb/donjigrad.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Donji Grad"), ", au sud : animée aussi, la ville basse s’étend autour de la rue Ilica et de la place Ban-Jelačić."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/hr/zagreb/novizagreb.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Novi Zagreb"), ", sur la rive sud de la Save : un ensemble de quartiers résidentiels construits après-guerre."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
